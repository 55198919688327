@import "../variables";

.page-loader {
    background-color: $dark-jungle-green;
    height: 100vh;
    width: 100%;

    .fake-header {
        height: 225px;
        background-color: $dark-jungle-green;
    }

    .fake-page-body {
        .preloader {
            position: absolute;
            top: 38%;
            left: 50%;
            z-index: 999;
            -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
        }

        .rocket-preloader {
            width: 60px;
            height: 60px;
        }

        .rocket-preloader:before,
        .rocket-preloader:after {
            content: "";
            display: block;
            background-color: $bright-red;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-animation: rocket-preloader_smoke 1s linear 1s infinite backwards;
            animation: rocket-preloader_smoke 1s linear 1s infinite backwards;
        }

        .rocket-preloader:after {
            top: 6px;
        }

        .rocket {
            width: 60px;
            height: 60px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggZmlsbD0iI2ZlZmVmZSIgZD0iTTE5LjYyMSAxOS43MDljMCAwIDExLjI5NC04LjE0OSAxMC4wNDItMTcuNDc3LTAuMDI5LTAuMjA1LTAuMDk2LTAuMzM5LTAuMTc5LTAuNDMtMC4wODgtMC4wODUtMC4yMTktMC4xNTUtMC40MjEtMC4xODQtOS4xMTUtMS4yNzgtMTcuMDc4IDEwLjI3OC0xNy4wNzggMTAuMjc4LTYuOTA5LTAuODI5LTYuNDA2IDAuNTUtOS41NTggOC4xMi0wLjYwMyAxLjQ0MyAwLjM3NCAxLjk0MSAxLjQ0NiAxLjUzNCAxLjA3Mi0wLjQwMyAzLjQzOC0xLjI5OCAzLjQzOC0xLjI5OGw0LjE0MiA0LjIzOGMwIDAtMC44NzQgMi40MjEtMS4yNjkgMy41MTctMC4zOTUgMS4wOTkgMC4wOTAgMi4wOTkgMS41MDEgMS40ODMgNy4zOTgtMy4yMjcgOC43NDYtMi43MTQgNy45MzYtOS43ODJ6TTIxLjIzNCAxMC4yNDNjLTAuOTU3LTAuOTc5LTAuOTU3LTIuNTY2IDAtMy41NDYgMC45NTctMC45NzggMi41MDctMC45NzggMy40NjQgMCAwLjk1OCAwLjk3OCAwLjk1OCAyLjU2NiAwIDMuNTQ2LTAuOTU1IDAuOTgxLTIuNTA3IDAuOTgxLTMuNDY0IDB6Ij48L3BhdGg+PC9zdmc+);
            position: absolute;
            top: 0;
            left: 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-animation: rocket-preloader 1s linear infinite alternate backwards;
            animation: rocket-preloader 1s linear infinite alternate backwards;
        }

        @-webkit-keyframes rocket-preloader {
            0% {
                -webkit-transform: translate3d(0, 3px, 0) rotate(45deg);
                transform: translate3d(0, 3px, 0) rotate(45deg);
            }

            100% {
                -webkit-transform: translate3d(6px, 6px, 0) rotate(45deg);
                transform: translate3d(6px, 6px, 0) rotate(45deg);
            }
        }

        @keyframes rocket-preloader {
            0% {
                -webkit-transform: translate3d(0, 3px, 0) rotate(45deg);
                transform: translate3d(0, 3px, 0) rotate(45deg);
            }

            100% {
                -webkit-transform: translate3d(6px, 6px, 0) rotate(45deg);
                transform: translate3d(6px, 6px, 0) rotate(45deg);
            }
        }

        @-webkit-keyframes rocket-preloader_smoke {
            0%,
            100% {
                -webkit-transform: translate3d(12px, 30px, 0);
                transform: translate3d(12px, 30px, 0);
                opacity: 0;
            }

            5% {
                opacity: 1;
            }

            70% {
                -webkit-transform: translate3d(-60px, 30px, 0) rotate(45deg);
                transform: translate3d(-60px, 30px, 0) rotate(45deg);
                opacity: 0;
            }
        }

        @keyframes rocket-preloader_smoke {
            0%,
            100% {
                -webkit-transform: translate3d(12px, 30px, 0);
                transform: translate3d(12px, 30px, 0);
                opacity: 0;
            }

            5% {
                opacity: 1;
            }

            70% {
                -webkit-transform: translate3d(-60px, 30px, 0) rotate(45deg);
                transform: translate3d(-60px, 30px, 0) rotate(45deg);
                opacity: 0;
            }
        }
    }
}
