@import "../variables";
.created-hired-form-component {

    .verify-card,
    .create-form-card {
        background: $white;
        border-radius: 10px;
        border: 0;
        box-shadow: 0 0 10px hwb(0 84% 15%);
    }

    .verify-card {
        width: 700px;

        .note-text {
            font-size: 15px
        }
    }

    .verify-modal-component {
        img {
            width: 250px;
        }

        h3 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            color: $black-bean;

            @media screen and (max-width: 595px) {
                font-size: 25px;
            }

        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: $black-Eel;
            opacity: 0.8;
        }

        .btn-outline-danger {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            border: 1px solid $deep-pink;
            color: $deep-pink;
            text-decoration: none;

            &:hover {
                background: unset;
            }
        }
    }




    .profile {
        img {
            width: 170px;
        }

        .preview-img {
            width: 170px;
            height: 170px;
            border-radius: 50%;


            &:hover {
                opacity: 0.5;
            }
        }

        label {
            position: relative;

            &::after {
                content: "\f304";
                position: absolute;
                right: 77%;
                top: 70%;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                height: 40px;
                width: 40px;
                color: $white;
                font-size: 1.4em;
                border-radius: 50%;
                background-color: rgb(156, 231, 222);
                padding: 8px;
                display: none;
                cursor: pointer;
            }


            &:hover::after {
                display: block;
            }

        }

        @media screen and (min-width: 768px) {
            width: 50%;
        }

    }

    .label-not-edit {
        label {
            text-transform: capitalize;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: $cool-gery;
            margin-bottom: 5px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: $black-Eel;
        }
    }



    .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        color: $black-bean;
    }

    .company-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        color: $black-bean;

    }

    label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $black-Eel;
    }

    .input-gap {
        padding: 10px 15px;
    }

    .typeahead-gap {
        height: 46px;
    }

    .text-danger-custom {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $valentine-red;
    }

    .verify-btn {
        margin-bottom: 35px;
        padding: 10px 25px;
    }

    .not-allowed {
        cursor: not-allowed;
    }

    .btn-primary-custom {
        background: $violet-blue;
        color: $white;
        padding: 10px 25px;
    }

    .cursor-pointer-event {
        cursor: pointer;
    }
}