@import "../variables";

.hired-form-component {
    font-family: 'Inter';


    //add hired form 
    .add-hired-component {
        height: 400px;

        .add-icon {
            .faUser {
                font-size: 40px;
                color: $davy-grey2;
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin: 30px 0;
            color: $grey-mist;
        }

    }
}