@import "../variables";

.upload-file-component {
    .drop-file {
        background: $ghost-white2;
        /* Primary-100 */

        border: 1px dashed $purplish-blue;
        border-radius: 10px;

        .upload-label {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            color: $black-Eel;

        }

        .upload-notice {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: $dove-grey2;

        }

        .uplodding-mode {
            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                color: $black-Eel;

            }

            .cancle-btn {
                font-family: 'Inter';
                background: $white;
                border: 1px solid $violet-blue;
                border-radius: 5px;
                color: $black-Eel;
                font-size: 18px
            }

        }

        .letter-preview {
            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                color: #424242;

            }
        }
    }
}