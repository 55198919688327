$black: #000000;
$lite-black: #161616;
$white: #ffffff;
$bright-blue: #1858fb;
$dark-blue-grey: #1d3444;
$medium-black: #000000cc;
$warm-blue: #1d3444cc;
$alice-blue: #f1f7ff;
$dawn-pink: #ebebec;
$dark: #1b2430;
$regent-grey: #8d9298;
$powder-ash: #c4c4c4;
$bright-blue: #18a0fb;
$davy-grey: #495059;
$davy-grey2: #595858;
$prussian-blue: #063255;
$cloud-burst: #263959;
$cloud-burst-lite: #2c2f62;
$lavender-mist: #e7e8f2;
$grey-light: #5b5b5b;
$desert-storm: #f8f8f8;
$ghost-white: #f8f9fd;
$ghost-white2: #faf7ff;
$clear-blue: #197aff;
$cinder-black: #081016;
$orangey-yellow: #ffb800;
$milk-white: #f9fbfd;
$red-orange: #fc3838;
$flush-orange: #ff7a00;
$sea-green: #2faa52;
$nero: #1c1900;
$golden-gradient: linear-gradient(270deg, #fcad45, #fecd5b);
$maroon-flush: #c72241;
$electric-violet: #6d20ea;
$mine-shaft-11: #2b2b2b1c;
$alto: #d8d8d8;
$slate-grey: #768192;
$dark-slate-grey: #2f2f41;
$light-grey: #d8dbe0;
$dark-grey: #353536;
$hit-grey: #a7a7a7;
$mid-grey: #626262;
$purple-blue: #6644f7;
$purple-blue2: #5b34f1;
$purply-blue: #f5f3ff;
$dusty-grey: #969696;
$hot-pink: #ff589b;
$deep-pink: #ff136f;
$bright-red: #ff0000;
$dawn-grey: #9fa2b4;
$pale-grey: #fcfdfe;
$Geyser: #dfe0eb;
$sidebar-bg: #363740;
$sidebar-active-item: #dde2ff;
$royal-purple: #3f0c67;
$gunsmoke: #858585;
$quill-grey: #d6d6d6;
$white-smoke: #f5f5f5;
$tealish-blue: #f0f1f7;
$tealish-blue2: #888b9c;
$tealish-blue3: #6074ff;
$tealish-blue-dark: #3751ff;
$red: #ff1515;
$light-red: #f94f4f;
$valentine-red: #e75353;
$catskill-white: #f2f4f8;
$lapis-blue: #1c2074;
$bluish-cyan: #21a9ff;
$medium-grey: #7e7e7e;
$dark-jungle-green: #1e1e1e;
$smokey-grey: #747474;
$black-grey: #494949;
$jade-green: #2eb85c;
$black-bean: #131313;
$platinum: #e7e7e7;
$cool-gery: #a1a1a1;
$black-Eel: #424242;
$violet-blue: #5a00dd;
$grey-mist: #989898;
$purplish-blue: #6808ff;
$dove-grey2: #717171;
$regal-blue: #13446a;
$blue-haze: #b3c5d3;
$cream: #e3aaff;
$gray05: #e8e8e8;
