.custom-modal-componet {

    .custom-modal-box {
        height: 100%;
        max-height: 600px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .custom-modal-box {
        &::-webkit-scrollbar {
            width: 5px !important;
        }
    }

}