@font-face {
    font-family: Gilroy;
    src: url("../assets/fonts/gilroy/Gilroy-ExtraBold.otf");
}

@font-face {
    font-family: Gilroy-light;
    src: url("../assets/fonts/gilroy/Gilroy-Light.otf");
}

body {
    font-family: "Space Grotesk";
    background-color: #1e1e1e;
}
