@import "../variables";

.forgot-password {
    .card {
        border: 0;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 25px;
        width: 500px !important;
        input{
            border-radius: 40px !important;
            padding: 10px;
        }
    }

    .francy-btn {
        background: linear-gradient(-45deg, $hot-pink, $deep-pink) !important;
        padding: 15px 42px !important;
        border-radius: 40px !important;
        position: relative;
        white-space: nowrap;
        padding-right: 65px !important;
    }

    .francy-btn::after {
        content: "\f178";
        position: absolute;
        right: 5px;
        top: 5px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        height: 42px;
        width: 42px;
        background: $white;
        color: $light-red;
        border-radius: 50%;
        display: block;
        font-size: 1.4em;
        padding-top: 6px;
    }
}