@import "../variables";

.header-component {
  //sticky navbar style
  .sticky {
    background: rgba(7, 7, 35, 0.75);
    backdrop-filter: blur(12.5px);
    -webkit-backdrop-filter: blur(12.5px);
  }

  //end
  nav {
    .navbar-brand {
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      background: linear-gradient(136.85deg, #ff37f2 -15.82%, #405aff 99.57%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    ul li a {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #e8e8e8 !important;
      position: relative;
      margin: 0 10px;
      // &::before {
      //   content: "";
      //   position: absolute;
      //   width: 0px;
      //   height: 6px;
      //   bottom: 0;
      //   background-color: $clear-blue;
      //   border-radius: 21px;
      //   transition: 0.3s ease;
      // }

      // &:hover {
      //   &::before {
      //     width: 28px;
      //   }
      // }

      // &.active {
      //   &::before {
      //     width: 28px;
      //   }
      // }
    }
    @media (max-width: 1200px) {
      background: rgba(7, 7, 35, 0.75);
      backdrop-filter: blur(12.5px);
      -webkit-backdrop-filter: blur(12.5px);
    }
  }

  .log-reg-nav-item {
    & .nav-link {
      background: linear-gradient(97.64deg, #eaaaff 15.56%, #b5acff 92.85%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 700;
    }

    width: fit-content;
    border: 1px solid #ff37f2;
    border-radius: 10px;
  }
}
