@import "../variables";

.footer-component {
    .footer-section {
        .label {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: $white;
        }

        .items {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            color: #e8e8e8;
            text-decoration: none;

            .icon {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #e8e8e8;
            }
        }

        .items-uniqe {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: $white;
        }

        .items-uniq {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.8);
        }

        .social-links {
            a {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;
                transition: 0.3s ease-in-out;
            }

            img {
                width: 40px;
            }
        }

        .contact-card {
            .send-section {
                position: relative;
            }

            .input-box {
                background: #0e101f;
                border: 0;
                color: $white;
                border-radius: 10px;
                font-size: 18px;
                height: 64px;

                &::placeholder {
                    color: $blue-haze;
                }
            }

            .send-button {
                background: linear-gradient(260.06deg, #ff37f2 -4.88%, #405aff 89.47%);
                border-radius: 10px;
                width: 100px;
                height: 50px;
                position: absolute;
                top: 7px;
                right: 7px;
                & img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .footer-end {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: rgba(38, 57, 89, 0.65);
    }
}
